/* eslint-disable react/jsx-props-no-spreading */
import { IconName } from "@components/common/Icon";
import React from "react";

import { Field } from "formik";

import Input, { InputProps } from "./Input";

export type LabelOptions = {
  className?: string;
  onTop?: boolean;
};

export interface InputFieldProps extends InputProps {
  autoComplete?: string;
}

interface FieldProps {
  field: React.HTMLProps<HTMLInputElement>;
  meta: {
    touched: boolean;
    error: string;
  };
}

const InputField: React.FC<InputFieldProps> = ({
  name,
  submitCount,
  type,
  labelOptions = { onTop: false, className: undefined },
  mask,
  maskChar,
  autoComplete,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        return (
          <Input
            type={type}
            field={field}
            submitCount={submitCount}
            touched={meta.touched}
            error={meta.error}
            {...(field as any)}
            {...props}
            labelOptions={labelOptions}
            mask={mask}
            maskChar={maskChar}
            autoComplete={autoComplete}
          />
        );
      }}
    </Field>
  );
};

export default InputField;
