import * as yup from "yup";

const validationSchema = yup.object().shape({
  email: yup.string().email("Invalid email address").required("Email is required"),
  firstName: yup
    .string()
    .min(2, "First name must be at least 2 characters")
    .required("First name is required"),
  lastName: yup
    .string()
    .min(2, "Last name must be at least 2 characters")
    .required("Last name is required"),
  streetAddress: yup.string().required("Street address is required"),
  city: yup.string().required("City is required"),
  zipCode: yup
    .string()
    .matches(/^\d{5}(-\d{4})?$/, "Invalid zip code. Must be in the format 12345 or 12345-1234")
    .required("Zip code is required"),
  state: yup
    .string()
    .matches(
      // eslint-disable-next-line max-len
      /^(A[LKSZRAP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])$/i,
      "Invalid state. Must be a valid US state abbreviation"
    )
    .required("State is required"),
  phoneNumber: yup.string().matches(/^\+1 \(\d{3}\) \d{3}-\d{4}$/, "Invalid phone number"),
  cardNumber: yup
    .string()
    .matches(
      /^\d{4} \d{4} \d{4} \d{3}\d?$/,
      "Invalid card number. Must be in the format 9999 9999 9999 9999"
    )
    .required("Card number is required"),
  cardExpiry: yup
    .string()
    .matches(
      /^(0[1-9]|1[0-2])\/(\d{2}|\d{4})$/,
      "Invalid expiration date. Must be in the format MM/YY or MM/YYYY"
    )
    .test("is-valid-expiry", "Invalid expiration date", (value) => {
      if (!value || typeof value !== "string") return false;
      const [month, year] = value.split("/");

      if (!month || !year) return false;

      const monthNum = parseInt(month, 10);
      const yearNum = parseInt(year, 10);

      if (monthNum < 1 || monthNum > 12) return false;

      if (year.length === 2) {
        return yearNum >= 0 && yearNum <= 99;
      } else if (year.length === 4) {
        return yearNum >= 1900 && yearNum <= 2099;
      }

      return false;
    }),
  cardCode: yup
    .string()
    .matches(/^[0-9]{3,4}$/, "CVC code must be 3 or 4 digits")
    .required("CVC code is required"),
});

export default validationSchema;
