import { ShippingType } from "@components/checkout/CheckoutPaymentForm";

import { CartItem } from "@typings/cart";

import { getShippingCost } from "./getShippingCost";

export const getSubtotal = (cartItems: CartItem[]): number =>
  +cartItems
    .filter((item) => !item.isFree)
    .reduce((acc, item) => acc + item.product.price * item.quantity, 0);

export const getTotal = (
  cart: CartItem[],
  discount: number,
  shippingMethod: ShippingType
): number => getSubtotal(cart) - discount + getShippingCost(shippingMethod);
