import React, { FC } from "react";

import classnames from "classnames";

import { ShippingPrice } from "@components/checkout/CheckoutShippingForm";

export interface Option extends ShippingPrice {
  label: string;
  value: string;
}

interface Props extends React.HTMLProps<HTMLInputElement> {
  option: Option;
  itemClassName?: string;
}

const RadioButton: FC<Props> = ({ option, itemClassName, ...props }) => {
  return (
    <React.Fragment>
      <div className="radio-button-container">
        <input
          className={classnames("radio-button", itemClassName)}
          type="radio"
          checked={option.value === props.value}
          readOnly
          {...props}
        />
      </div>

      <div className="content-wrapper">
        <div className="label-wrapper">
          <label className={classnames("label", itemClassName)} htmlFor={option.value}>
            {option.label}
          </label>

          {option?.helper && <div className="helper">{option.helper}</div>}
        </div>

        {option?.price && <div className="shipping-price">{option?.price?.label}</div>}
      </div>
    </React.Fragment>
  );
};

export default RadioButton;
