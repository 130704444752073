import React, { FC } from "react";

const Spinner: FC = () => {
  return (
    <div className="spinner">
      <div className="spinner-ring">
        <div className="spinner-section" />
        <div className="spinner-section" />
        <div className="spinner-section" />
        <div className="spinner-section" />
      </div>
    </div>
  );
};

export default Spinner;
