/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import RadioButton, { Option } from "@components/common/RadioButton";

interface RadioButtonGroupProps {
  name: string;
  value: string;
  options: Option[];
  containerClassName?: string;
  itemClassName?: string;
  onChange: (value: string) => void;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  value,
  onChange,
  options,
  containerClassName = "list-group-item",
  itemClassName = "item",
  ...props
}) => {
  return (
    <React.Fragment>
      {options.map((option) => (
        <div
          key={option.value}
          className={containerClassName}
          onClick={() => onChange(option.value)}
        >
          <RadioButton option={option} itemClassName={itemClassName} value={value} {...props} />
        </div>
      ))}
    </React.Fragment>
  );
};

export default RadioButtonGroup;
