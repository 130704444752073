/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Dispatch, SetStateAction, useContext, createContext } from "react";

interface UseModal {
  toggleModal: (body?: JSX.Element, closeCrossOnly?: boolean) => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  modalBody: Record<string, JSX.Element | boolean | undefined> | undefined;
}

interface ContextProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setModalBody: Dispatch<
    SetStateAction<Record<string, JSX.Element | boolean | undefined> | undefined>
  >;
  modalBody: Record<string, JSX.Element | boolean | undefined> | undefined;
}

export const ModalContext = createContext<ContextProps>({
  isOpen: false,
  setIsOpen: () => {},
  setModalBody: () => {},
  modalBody: undefined,
});

const useModal = (): UseModal => {
  const { isOpen, setIsOpen, setModalBody, modalBody } = useContext(ModalContext);

  const toggleModal = (body?: JSX.Element, closeCrossOnly?: boolean): void => {
    setModalBody({ body, closeCrossOnly });
    setIsOpen(!isOpen);
  };

  return { toggleModal, isOpen, setIsOpen, modalBody };
};

export default useModal;
