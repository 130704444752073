import { Address } from "../types/Address";
import { KlaviyoAddress } from "../types/KlaviyoAddress";

export const transformAddressToKlaviyo = (params: {
  address: Address;
  customer: Partial<{ firstName: string; lastName: string }>;
  phone: string;
}): KlaviyoAddress => {
  const { address, customer, phone } = params;

  const result = {
    FirstName: customer.firstName!,
    LastName: customer.lastName!,
    Address1: address.addressLine1,
    Address2: "",
    City: address.city,
    State: address.state,
    Country: address.country,
    PostCode: address.zip,
    Phone: phone,
  };

  return result;
};
