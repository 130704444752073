/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, Fragment, useEffect, useRef, useState } from "react";

import classNames from "classnames";

import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import useOutsideClick from "@hooks/useOutsideClick";

interface Props {
  value: any;
  label?: string;
  onChange: (data: any) => void;
}

const PlacesAutocomplete: FC<Props> = ({ value, label, onChange }) => {
  const [isFocused, setFocused] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const autocomplete = useRef<HTMLDivElement>(null);

  useOutsideClick(autocomplete, () => setMenuIsOpen(false));

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGoogle({
    apiKey: process.env.GATSBY_GOOGLE_API_KEY,
  });

  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputFocus = (): void => {
    setFocused(true);
  };

  const handleInputBlur = (): void => {
    setFocused(false);
  };

  return (
    <div
      className={classNames("place-autocomplete-input", { focused: isFocused })}
      ref={autocomplete}
    >
      <div className="input-container places-autocomplete">
        <div
          className={classNames("field", {
            focused: isFocused,
            "has-value": !!value,
          })}
        >
          <label htmlFor="street-input" className="label">
            {label}
          </label>
          <input
            className="input"
            value={inputValue}
            name="street-input"
            autoComplete="street-address"
            onClick={() => setMenuIsOpen(true)}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              getPlacePredictions({
                input: evt.target.value,
                types: ["geocode", "establishment"],
                componentRestrictions: { country: "US" },
              });
              setInputValue(evt.target.value);
              onChange(evt.target.value);
            }}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
        </div>
      </div>
      {menuIsOpen && (
        <div className="places-menu">
          {!isPlacePredictionsLoading && (
            <Fragment>
              {placePredictions.map((item) => (
                <div
                  onClick={() => {
                    onChange(item);
                    setMenuIsOpen(false);
                  }}
                  className="menu-option"
                  key={item.description}
                >
                  <div>{item.description}</div>
                </div>
              ))}
            </Fragment>
          )}

          {!isPlacePredictionsLoading && !placePredictions.length && (
            <span className="no-options">No options</span>
          )}

          {isPlacePredictionsLoading && <span className="options-loading">Loading...</span>}
        </div>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
