import { AnyAction, ThunkAction, configureStore } from "@reduxjs/toolkit";

import CartReducer from "./cart";

const createStore = () =>
  configureStore({
    reducer: {
      cart: CartReducer,
    },
  });

type ConfiguredStore = ReturnType<typeof createStore>;
type StoreGetState = ConfiguredStore["getState"];

export type RootState = ReturnType<StoreGetState>;
export type AppDispatch = ConfiguredStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export default createStore;
