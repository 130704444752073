import React from "react";

import { Field } from "formik";

import Checkbox from "@components/common/Checkbox";

interface Props extends React.HTMLProps<HTMLInputElement> {
  description?: string;
}

const CheckboxField: React.FC<Props> = ({ name, description, ...props }) => {
  return (
    <Field name={name}>
      {({ field }: { field: React.HTMLProps<HTMLInputElement> }) => {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Checkbox description={description} {...field} {...props} />;
      }}
    </Field>
  );
};

export default CheckboxField;
