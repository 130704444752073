import React, { ReactElement } from "react";

import classNames from "classnames";

import Icon, { IconName } from "@components/common/Icon";

interface CheckoutLeaveBadgeProps {
  icon: IconName;
  title: ReactElement | string;
  subtitle: string;

  color?: "orange" | "green";
}

const CheckoutLeaveBadge = ({
  icon,
  title,
  subtitle,
  color = "orange",
}: CheckoutLeaveBadgeProps) => {
  return (
    <div className={classNames("checkout-leave__badge", color)}>
      <div className="checkout-leave__badge-icon">
        <Icon width={52} height={52} name={icon} />
      </div>

      <h4 className="checkout-leave__badge-title">{title}</h4>
      <h5 className="checkout-leave__badge-subtitle">{subtitle}</h5>
    </div>
  );
};

export default CheckoutLeaveBadge;
