import React, { useMemo } from "react";

import classNames from "classnames";

import ReactSelect, {
  GroupBase,
  Props,
  ValueContainerProps,
  components,
  InputProps,
} from "react-select";

const { ValueContainer, Input } = components;

export interface SelectOption {
  value: string;
  label: string;
}

export interface SelectProps<IsMulti extends boolean, Group extends GroupBase<SelectOption>>
  extends Props<SelectOption, IsMulti, Group> {
  name: string;
  color?: "gray";
  rounded?: "sm" | "md";
  bigness?: "sm" | "md";
  animatedPlaceholder?: boolean;
  options: SelectOption[];
}

const CustomValueContainer = <IsMulti extends boolean, Group extends GroupBase<SelectOption>>(
  { children, ...props }: ValueContainerProps<SelectOption, IsMulti, Group>,
  animatedPlaceholder?: boolean
): JSX.Element => {
  const {
    selectProps: { placeholder },
  } = props;

  return (
    <ValueContainer {...props}>
      {animatedPlaceholder && placeholder && (
        <span className={classNames("floating-placeholder")}>{placeholder}</span>
      )}
      {children}
    </ValueContainer>
  );
};

const CustomInput = <IsMulti extends boolean, Group extends GroupBase<SelectOption>>({
  ...props
}: InputProps<SelectOption, IsMulti, Group>): JSX.Element => {
  return <Input {...props} autoComplete="nope" />;
};

const Select = <
  IsMulti extends boolean = false,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
>({
  name,
  className,
  animatedPlaceholder,
  components: additionalComponents,
  ...props
}: SelectProps<IsMulti, Group>): JSX.Element => {
  const selectComponents = useMemo<SelectProps<IsMulti, Group>["components"]>(() => {
    return {
      ValueContainer: (componentProps) => CustomValueContainer(componentProps, animatedPlaceholder),
      Input: CustomInput,
      ...additionalComponents,
    };
  }, [additionalComponents, animatedPlaceholder]);

  const selectClassName = classNames("react-select", className, {
    [`color-${props.color}`]: props.color,
    [`rounded-${props.rounded}`]: props.rounded,
    [`bigness-${props.bigness}`]: props.bigness,
    "with-animated-placeholder": animatedPlaceholder,
  });

  return (
    <ReactSelect
      isSearchable
      id={name}
      instanceId={name}
      name={name}
      classNamePrefix="react-select"
      className={selectClassName}
      components={selectComponents}
      {...props}
    />
  );
};

export default Select;
