import React from "react";

type CartItemPropertyProps =
  | {
      name: string;
      value?: string;
    }
  | {
      name?: string;
      value: string;
    };

const CartItemProperty = ({ name, value }: CartItemPropertyProps) => {
  return (
    <div className="cart-item__property">
      {name && <span className="cart-item__property-name">{name}</span>}
      {value && <span className="cart-item__property-value">{value}</span>}
    </div>
  );
};

export default CartItemProperty;
