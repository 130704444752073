import React from "react";

export interface ImageGalleryItem {
  name: string;
  src: string;
}

interface ImageGalleryProps {
  images: ImageGalleryItem[];

  maxHeight: number;
}

const ImageGallery = ({ images, maxHeight }: ImageGalleryProps) => {
  return (
    <div className="image-gallery">
      {images.map((image) => (
        <img
          className="image-gallery__item"
          key={image.name}
          src={image.src}
          alt={image.name}
          style={{ maxHeight }}
        />
      ))}
    </div>
  );
};

export default ImageGallery;
