import React, { FC, memo, ReactNode, useState } from "react";

import { ModalContext } from "@hooks/useModal";

type Props = {
  children: ReactNode;
};

const ModalProvider: FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalBody, setModalBody] = useState<
    Record<string, JSX.Element | boolean | undefined> | undefined
  >(undefined);

  return (
    <ModalContext.Provider value={{ isOpen, setIsOpen, modalBody, setModalBody }}>
      {children}
    </ModalContext.Provider>
  );
};

export default memo<FC<Props>>(ModalProvider);
