import React from "react";

import classNames from "classnames";
import { Information } from "scripts/generateInformationJson";

interface CartItemImgProps {
  information: Information;
  redirectToProductPage: () => void;
}

const CartItemImg = ({ information, redirectToProductPage }: CartItemImgProps) => {
  return (
    <button type="button" onClick={redirectToProductPage} className={classNames("cart-item__img")}>
      <img
        className="cart-item__img-content"
        src={information?.images?.main || ""}
        alt={information?.name || ""}
      />
    </button>
  );
};

export default CartItemImg;
