import React, { useState, useMemo, useRef, forwardRef } from "react";

import { Tooltip as ReactTooltip } from "react-tooltip";

import classnames from "classnames";

import InputMask from "react-input-mask";

import { LabelOptions } from "@components/common/InputField";
import Icon, { IconName } from "@components/common/Icon";

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  rounded?: "sm" | "md";
  bigness?: "sm" | "md";
  error?: string;
  touched?: boolean;
  field?: any;
  mask?: string;
  maskChar?: string;
  submitCount?: number;
  labelOptions?: LabelOptions;
  iconName?: IconName;
  tooltip?: string;
  clearable?: boolean;
  iconClassName?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      type,
      label,
      className,
      error,
      value,
      onFocus,
      onBlur,
      touched,
      submitCount,
      labelOptions = { onTop: false, className: undefined },
      field,
      mask = undefined,
      maskChar = "_",
      iconName,
      tooltip,
      iconClassName,
      clearable,
      ...props
    },
    ref
  ) => {
    const [isAutoFilled, setIsAutoFilled] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const [isShowPassword, setShowPassword] = useState(false);

    const input = useRef<HTMLInputElement | null>(null);
    // const inputRef = useMergedRef(input, ref);

    const { onTop: labelOnTop, className: labelClassName } = labelOptions;

    const toggle = (): void => {
      setShowPassword(!isShowPassword);
      input.current?.focus();
    };

    const handleClearValue = (): void => {
      props.onChange?.({
        ...input.current,
        target: { name, value: "" },
      } as React.ChangeEvent<HTMLInputElement>);
    };

    const isPassword = type === "password";
    const hasError = touched && !!error;

    const inputType = useMemo(() => {
      if (isPassword) {
        return isShowPassword ? "text" : type;
      }

      return type;
    }, [isPassword, type, isShowPassword]);

    const fieldClassName = classnames("field", {
      focused: isFocused,
      [`color-${props.color}`]: props.color,
      [`rounded-${props.rounded}`]: props.rounded,
      [`bigness-${props.bigness}`]: props.bigness,
      "has-value": !!value || isAutoFilled,
      "has-error": hasError && typeof submitCount === "number" && submitCount > 0,
      "is-password": isPassword,
      "is-clearable": clearable,
    });

    const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
      setFocused(true);
      if (onFocus) onFocus(event);
    };

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      setFocused(false);
      if (onBlur) onBlur(event);
    };

    const handleAutoFill = (e: React.AnimationEvent<HTMLInputElement>): void => {
      setIsAutoFilled(e.animationName === "onAutoFillStart");
    };

    return (
      <div className="input-container">
        {labelOnTop && (
          <label htmlFor={id || name} className={classnames("label", labelClassName)}>
            {label}
          </label>
        )}

        <div className={fieldClassName}>
          <InputMask
            maskChar={maskChar}
            mask={mask}
            {...field}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            disabled={props.disabled}
          >
            {(inputProps: any) => (
              <input
                {...inputProps}
                id={id || name}
                className={classnames("input", className, {
                  "label-on-top": labelOnTop,
                  "without-label": !label,
                })}
                name={name}
                value={value}
                {...props}
                ref={input}
                type={inputType}
                onAnimationStart={handleAutoFill}
              />
            )}
          </InputMask>

          {!labelOnTop && (
            <label htmlFor={id || name} className={classnames("label", labelClassName)}>
              {label}
            </label>
          )}

          {iconName && <Icon className={iconClassName} name={iconName} id={iconName} />}

          {tooltip && (
            <ReactTooltip
              anchorSelect={`#${iconName}`}
              place="bottom"
              content={tooltip}
              positionStrategy="fixed"
            />
          )}

          {clearable && !!value?.toString().length && (
            <button
              type="button"
              onClick={handleClearValue}
              className="input-clear-btn"
              tabIndex={-1}
            >
              <Icon name="cross" width={12} height={12} />
            </button>
          )}

          {isPassword && (
            <button type="button" onClick={toggle} className="show-password-btn" tabIndex={-1}>
              {isShowPassword ? "hide" : "show"}
            </button>
          )}
        </div>

        {hasError && typeof submitCount === "number" && submitCount > 0 && (
          <div className="error">{error}</div>
        )}
      </div>
    );
  }
);

export default Input;
