import { CartItem } from "@typings/cart";

import { GACartItem } from "../types/GACartItem";

export const transformCartItemsToGA = (cartItems: CartItem[]): GACartItem[] => {
  return cartItems.map((cartItem, index) => {
    const { quantity, product, isFree } = cartItem;

    const productPrice = isFree ? 0 : product?.price;

    return {
      index,
      item_id: product?.id,
      item_name: product?.name,
      item_brand: product?.sku,
      item_category4: product?.deliveryFrequency,
      item_category5: product?.color,
      price: productPrice,
      quantity: quantity || 1,
      currency: "USD",
      item_category: "Best Sellers",
      item_category2: "Health & wellness",
    };
  });
};
