import React, { FC } from "react";

import useProduct from "@hooks/useProduct";

import Section from "@components/common/section/Section";

import Icon from "@components/common/Icon";
import MobileBenefits from "./mobile/MobileBenefits";

const Benefits: FC = () => {
  const { product, information } = useProduct();

  return (
    <React.Fragment>
      <Section className="benefits" centered>
        <h2 className="section-title-text">{`${product.name} Benefits`}</h2>

        <div className="product-benefits">
          <div className="benefits-column">
            {information.benefits.length === 6
              ? information.benefits.slice(0, 3).map((benefit) => (
                  <div key={benefit.title} className="benefits-section">
                    <Icon name={benefit.icon} className="section-img" />

                    <div className="section-info">
                      <h4 className="section-title">{benefit.title}</h4>
                      <p className="section-text">{benefit.text}</p>
                    </div>
                  </div>
                ))
              : information.benefits.slice(0, 4).map((benefit) => (
                  <div key={benefit.title} className="benefits-section">
                    <Icon name={benefit.icon} className="section-img" />

                    <div className="section-info">
                      <h4 className="section-title">{benefit.title}</h4>
                      <p className="section-text">{benefit.text}</p>
                    </div>
                  </div>
                ))}
          </div>

          <div className="benefits-column image">
            {information.images.benefits.endsWith(".mp4") ? (
              <video autoPlay loop muted playsInline>
                <source src={information.images.benefits} />
              </video>
            ) : (
              <img
                alt={product.name}
                src={information.images.benefits}
                width={415}
                height={549}
                loading="lazy"
              />
            )}
          </div>

          <div className="benefits-column">
            {information.benefits.length === 6
              ? information.benefits.slice(3, 6).map((benefit) => (
                  <div key={benefit.title} className="benefits-section">
                    <Icon name={benefit.icon} className="section-img" />

                    <div className="section-info">
                      <h4 className="section-title">{benefit.title}</h4>
                      <p className="section-text">{benefit.text}</p>
                    </div>
                  </div>
                ))
              : information.benefits.slice(4, 7).map((benefit) => (
                  <div key={benefit.title} className="benefits-section">
                    <Icon name={benefit.icon} className="section-img" />

                    <div className="section-info">
                      <h4 className="section-title">{benefit.title}</h4>
                      <p className="section-text">{benefit.text}</p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </Section>

      <MobileBenefits
        name={product.name}
        benefits={information.benefits}
        image={information.images.benefits}
        className="image"
      />
    </React.Fragment>
  );
};

export default Benefits;
