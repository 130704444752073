import { CartItem } from "@typings/cart";

export const applyBOGO = (cartItems: CartItem[], cartItem: CartItem) => {
  const { product } = cartItem;

  /* ============
    Apply BOGO only to the products with the same type
    ============= */
  const filteredProducts = cartItems
    .filter((item) => item.product.type === product.type)
    .sort((a, b) => a.product.price - b.product.price);

  const notFilteredProducts = cartItems.filter((item) => item.product.type !== product.type);

  /* ============
    Calculating free/paid products
    =============
  */
  let quantityIndex = 1;

  const freeItemsQuantity = Math.floor(
    filteredProducts.reduce((acc, item) => acc + item.quantity, 0) / 2
  );

  const separatedProducts = filteredProducts.reduce((acc: CartItem[], item) => {
    const { quantity } = item;

    for (let index = 0; index < quantity; index++) {
      const isFree = index + quantityIndex <= freeItemsQuantity;

      const existingItem = acc.find(
        (accItem) => !!accItem.isFree === isFree && accItem.product.id === item.product.id
      );

      if (!existingItem) {
        acc.push({ ...item, isFree, quantity: 1 });
      } else {
        acc = acc.map((accItem) =>
          !!accItem.isFree === isFree && accItem.product.id === item.product.id
            ? { ...accItem, quantity: accItem.quantity + 1 }
            : accItem
        );
      }
    }

    quantityIndex += quantity;

    return acc;
  }, []);

  return [...notFilteredProducts, ...separatedProducts];
};
