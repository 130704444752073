/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, Dispatch, SetStateAction } from "react";

import classnames from "classnames";
import { Element } from "react-scroll";

import Icon, { IconName } from "@components/common/Icon";

import { FaqSectionData } from "@configs/faqSectionData";

import scrollTo from "@helpers/scrollTo";

type Accordion = FaqSectionData & {
  elementName?: string;
  className?: string;
  cartTitle?: boolean;
  titleClassName?: string;
  setIsActive: Dispatch<SetStateAction<Record<string, boolean>>>;
  isActive: Record<string, boolean>;
  cartTotal?: number;
  iconName?: IconName;
  oldTotalCart?: number;
};

const Accordion: FC<Accordion> = ({
  title,
  content,
  name,
  elementName,
  setIsActive,
  isActive,
  className,
  cartTitle,
  titleClassName,
  cartTotal,
  iconName,
  oldTotalCart,
}) => {
  const toggleActiveItem = (): void => {
    setIsActive({ ...isActive, [name]: !isActive[name] });
  };

  const handleClick = (): void => {
    toggleActiveItem();
  };

  useEffect(() => {
    if (elementName) {
      scrollTo(elementName);
      setIsActive({ ...isActive, [elementName]: true });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [elementName]);

  const currentIconName = iconName || (isActive[name] ? "minus" : "plus");

  return (
    <Element className={classnames("accordion-item", className)} name={name || ""}>
      {!cartTitle ? (
        <div
          className={classnames("accordion-title", { open: isActive[name] })}
          onClick={handleClick}
        >
          <p className="text bold">{title}</p>
          <div className="icon">
            <Icon name={currentIconName} />
          </div>
        </div>
      ) : (
        <div className={classnames("accordion-title", titleClassName)} onClick={handleClick}>
          <div className="accordion-title-cart">
            <p className="text">
              {isActive[name] ? "Hide" : "Show"} {title}
            </p>
            <div className="icon-chevron">
              {isActive[name] ? (
                <Icon name="chevron" width={12} height={12} className="open" />
              ) : (
                <Icon name="chevron" width={12} height={12} />
              )}
            </div>
          </div>

          <div className="cart-total">
            <span>${oldTotalCart?.toFixed(2)}</span>
            <span>${cartTotal?.toFixed(2)}</span>
          </div>
        </div>
      )}
      <div
        className={classnames("accordion-content", {
          "is-opened": isActive[name],
        })}
      >
        <div className="text">{content}</div>
      </div>
    </Element>
  );
};

export default Accordion;
