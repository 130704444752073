import React, { PropsWithChildren } from "react";

import Icon, { IconName } from "@components/common/Icon";

interface CheckoutGuaranteesItemProps extends PropsWithChildren {
  icon: IconName;
  title: string;
}

const CheckoutGuaranteesItem = ({ icon, title, children }: CheckoutGuaranteesItemProps) => {
  return (
    <div className="checkout-guarantees__item">
      <div className="checkout-guarantees__item-header">
        <Icon className="checkout-guarantees__item-icon" width={24} height={24} name={icon} />
        <h4 className="checkout-guarantees__item-title">{title}</h4>
      </div>

      {children && <div className="checkout-guarantees__item-body">{children}</div>}
    </div>
  );
};

export default CheckoutGuaranteesItem;
