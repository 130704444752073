export const formatTimeInMs = (milliseconds: number) => {
  const hours = Math.floor(milliseconds / (1000 * 60 * 60))
    .toString()
    .padStart(2, "0");

  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, "0");

  const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000)
    .toString()
    .padStart(2, "0");

  return {
    hours,
    minutes,
    seconds,
  };
};
