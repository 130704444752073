/* eslint-disable jsx-a11y/no-static-element-interactions */
import Icon from "@components/common/Icon";
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

import classnames from "classnames";

interface Props extends React.HTMLProps<HTMLInputElement> {
  description?: string;
}

const Checkbox: React.FC<Props> = ({
  id,
  name,
  label,
  value,
  description,
  checked,
  className,
  ...props
}) => {
  return (
    <label
      className={classnames("input-container", className, {
        [`checkbox-${props.color}`]: props.color,
        "with-description": !!description,
      })}
      htmlFor={id || name}
    >
      <input
        {...props}
        id={id || name || ""}
        name={name}
        type="checkbox"
        checked={checked || !!value}
      />
      <span className="checkmark" />
      {label && <label htmlFor={name}>{label}</label>}
      {description && (
        <label htmlFor={id || name} className="checkbox-description">
          {description}
        </label>
      )}
    </label>
  );
};

export default Checkbox;
