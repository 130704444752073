import type { CartItem } from "@typings/cart";

import { areCartItemsEqual } from "../common/areCartItemsEqual";

export const addCartItems = (cartItems: CartItem[], newCartItems: CartItem[]) => {
  return newCartItems.reduce((acc, cartItem) => {
    const existingItem = acc.find((item) => areCartItemsEqual(item, cartItem));

    if (existingItem) {
      return acc.map((item) => {
        if (areCartItemsEqual(item, cartItem)) {
          return { ...item, quantity: item.quantity + cartItem.quantity };
        }

        return item;
      });
    }

    return [...acc, cartItem];
  }, cartItems);
};
