import React, { FC } from "react";

import Icon from "@components/common/Icon";
import classNames from "classnames";

type CounterProps = {
  className?: string;
  count: number;
  setCount: (newCount: number) => void;
};

const Counter: FC<CounterProps> = ({ className, count, setCount }) => {
  return (
    <div className={classNames("counter", className)}>
      <button type="button" onClick={() => setCount(count - 1)}>
        <Icon name="minus" className="minus-icon" />
      </button>

      <input type="number" value={count} onChange={(e) => setCount(+e.currentTarget.value)} />

      <button type="button" onClick={() => setCount(count + 1)}>
        <Icon name="plus" className="plus-icon" />
      </button>
    </div>
  );
};

export default Counter;
