import React, { useMemo } from "react";

import ImageGallery, { ImageGalleryItem } from "../ImageGallery";

const srcBase = "/images/common/security-certifications";

const SecurityCertificationsGallery = () => {
  const certifications: ImageGalleryItem[] = useMemo(() => {
    return ["visa", "mastercard", "safe-key", "protect-buy", "jcb", "pci", "trusted-site"].map(
      (cert) => ({
        name: cert,
        src: `${srcBase}/${cert}.png`,
      })
    );
  }, []);

  return <ImageGallery maxHeight={30} images={certifications} />;
};

export default SecurityCertificationsGallery;
