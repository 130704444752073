import { AnyProduct, BedProduct, BeltProduct, ChewProduct } from "@typings/product";
import { MedBoxProduct } from "@typings/product/medbox/MedBoxProduct";

export const isBedProduct = (product: AnyProduct): product is BedProduct => product.type === "BED";

export const isBeltProduct = (product: AnyProduct): product is BeltProduct =>
  product.type === "SEATBELT";

export const isChewProduct = (product: AnyProduct): product is ChewProduct =>
  product.type === "CHEW" || product.type === "UPSELL";

export const isMedBoxProduct = (product: AnyProduct): product is MedBoxProduct =>
  product.type === "MEDBOX";
