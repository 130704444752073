import products from "@data/products.json";

import { Product, ProductJson } from "scripts/generateProductsJson";

const typedProducts = products as ProductJson;

export const getProductById = (id: Product["id"]) => {
  return (products as ProductJson)[id];
};

export const getFreeGift = () => {
  const SKUs = ["freestrt1"];

  const freeGifts = Object.values(products).filter(({ sku }) => SKUs.includes(sku)) as Product[];

  return freeGifts.map((item) => ({
    ...item,
    image: `/images/gifts/${item.id}.png`,
  }));
};

export default (productQuery: Partial<Product> = {}, productsSku?: string[]) => {
  const onceProductQuery = { ...productQuery, subscriptionType: "NONE" };

  const productKeys = Object.keys(productQuery) as (keyof Product)[];
  const onceProductKeys = Object.keys(onceProductQuery) as (keyof Product)[];

  return Object.values(typedProducts)
    .filter((productEl) => productEl.type !== "VAPE") /* Hide vapes for some time */
    .filter((productEl) => {
      const matchesQuery = productKeys.every((key) => productEl[key] === productQuery[key]);
      const matchesIds = productsSku ? productsSku.includes(productEl.sku) : true;

      return matchesQuery && matchesIds;
    })
    .map((productEl) => {
      const onceProduct = Object.values(typedProducts).find((onceProductEl) =>
        onceProductKeys.every((key) => onceProductEl[key] === onceProductQuery[key])
      );

      if (productEl.price !== onceProduct?.price) {
        return {
          ...productEl,
          oldPrice: onceProduct?.price,
        };
      }

      return productEl;
    });
};
