import type { CartItem } from "@typings/cart";

import { applyBOGO } from "./bogo/applyBOGO";

const PROMOS_MAP = {
  withBOGO: applyBOGO,
};

export const applyPromos = (cartItems: CartItem[]) => {
  let updatedCartItems = cartItems;

  cartItems.forEach((item) => {
    if (item.promos) {
      item.promos.forEach((promo) => {
        updatedCartItems = PROMOS_MAP[promo](updatedCartItems, item);
      });
    }
  });

  return updatedCartItems;
};
