import React, { ReactElement } from "react";

export type FaqSectionData = {
  title: string | ReactElement;
  content: string | JSX.Element;
  name: string;
};

const faqSectionData: FaqSectionData[] = [
  {
    name: "moods",
    title: "How do you create the different moods?",
    content: (
      <React.Fragment>
        <p>
          We hand-select THC strains for their degrees of varying cannabinoids and terpene profiles.
          We then enhance these naturally occurring profiles by adding additional cannabinoids and
          terpenes to further promote a given mood.
        </p>
      </React.Fragment>
    ),
  },
  {
    name: "third-party",
    title: "Do you third-party test your products?",
    content: (
      <React.Fragment>
        <p>
          All of our products undergo testing in accredited laboratories to ensure accurate and
          reliable results. Each product has a corresponding Certificate of Analysis (COA) which can
          be found on the product page.
        </p>
      </React.Fragment>
    ),
  },
  {
    name: "refund-policy",
    title: "How can I return or exchange an item?",
    content: (
      <React.Fragment>
        <p>
          Here at Groove, all orders pass through a quality check before shipping. If you are
          unsatisfied with your purchase, please reach out to our customer support.
        </p>
        <p>For any items bought during a promotion, all sales are final</p>
        <p>
          For other items, we would be happy to assist you with your exchange or return provided
          that they are unused, sealed and in original condition. Valid reasons for return and
          exchange include the following:
        </p>

        <ul>
          <li>
            Product that is fundamentally different in nature from the product specified in the
            website;
          </li>
          <li>Faulty, defective, or damaged product;</li>
          <li>Product that is not as advertised on the website;</li>
          <li>
            Wrong item is delivered; Item has missing parts or accessories or Incomplete Order;
          </li>
        </ul>

        <p>
          Any refund request and cancellation are subject to approval and turn around time is
          24-48hours. This gives us time to check with our fulfillment if your order is processed or
          can still be cancelled. If you want a refund/cancellation after placing your order, please
          contact support. Our support team will assist you with the return/exchange process. Simply
          reach out to customer support.
        </p>
      </React.Fragment>
    ),
  },
  {
    name: "shipping-and-delivery",
    title: "Shipping & Delivery",
    content: (
      <React.Fragment>
        <p>Delivery usually takes 1-2 days from your purchase date.</p>
        <p>
          May it be a gift for someone or for your own, we will make sure to get it
          delivered to you ASAP as we process them in our systems within 24-48 hours of receiving
          the order from you!
        </p>
        <p>
          However we have found that some delivery times can take slightly longer than expected! If
          your package is arriving late, please be patient! For sure, its worth the wait.
        </p>
      </React.Fragment>
    ),
  },
  {
    name: "shipping-time",
    title: "How long does it take to ship",
    content:
      "Each order takes 2-3 business days to be delivered. You can always use your order number to track the shipment be clicking here.",
  },
  {
    name: "marijuana",
    title: "Does this feel like marijuana?",
    content:
      "Yes. THCa Hemp looks, smells, tastes, and gets you high like marijuana. One of the many cannabinoids found in the THC plant, THCa is the precursor to Delta-9 THC — the cannabinoid responsible for the “high” associated with marijuana. Once THCa is heated or smoked, it converts directly to Delta-9 THC and produces the same psychoactive effects.",
  },
  {
    name: "cancel",
    title: "How can I cancel my Subscription?",
    content:
      "You can pause, extend or cancel your subscription anytime by emailing support@officialgroove.com",
  },
];

export default faqSectionData;
