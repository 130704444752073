import cartSlice from "./cartSlice";

import { updateItemsQuantities } from "./actions/updateItemsQuantities";

import {
  selectCartItems,
  selectIsCartOpen,
  selectTotalPrice,
  selectIsCartLoaded,
  selectOldTotalPrice,
  selectAvailableBOGOFreeProducts,
} from "./selectors/cartSliceSelectors";

export default cartSlice.reducer;

export const {
  addToCart,
  closeCart,
  getItemsFromLocalStorage,
  getItemsFromURL,
  openCart,
  removeFromCart,
  toggleCart,
  clearCart,
} = cartSlice.actions;

export {
  selectCartItems,
  selectIsCartOpen,
  selectTotalPrice,
  selectIsCartLoaded,
  selectOldTotalPrice,
  selectAvailableBOGOFreeProducts,
  updateItemsQuantities,
};
